<template>
  <div class="row bg-white">
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
      <c-card title="공정별 판단결과" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <apexchart 
              ref="riskBeforeChart" 
              height="150" 
              type="bar"
              :width="riskBeforeChart.chartWidth"
              :options="riskBeforeChart.chartOptions" 
              :series="riskBeforeChart.series">
            </apexchart>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <apexchart 
              ref="riskAfterChart" 
              height="150"
              type="bar"
              :width="riskAfterChart.chartWidth"
              :options="riskAfterChart.chartOptions" 
              :series="riskAfterChart.series">
            </apexchart>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
      <c-card title="재해유형별 판단결과" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <apexchart 
              ref="hazardDisasterChart" 
              height="350" 
              type="bar"
              :width="hazardDisasterChart.chartWidth"
              :options="hazardDisasterChart.chartOptions" 
              :series="hazardDisasterChart.series">
            </apexchart>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
      <c-card title="공정별 개선 현황" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <apexchart 
              ref="processImprChart" 
              height="240" 
              type="bar"
              :width="processImprChart.chartWidth"
              :options="processImprChart.chartOptions" 
              :series="processImprChart.series">
            </apexchart>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
      <c-card title="공정별 위험등록부 현황" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <apexchart 
              ref="processRiskRegisterChart" 
              height="240" 
              type="bar"
              :width="processRiskRegisterChart.chartWidth"
              :options="processRiskRegisterChart.chartOptions" 
              :series="processRiskRegisterChart.series">
            </apexchart>
          </div>
        </template>
      </c-card>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import selectConfig from '@/js/selectConfig';
export default {
  name: 'fmStatus',
  components: {
    apexchart: VueApexCharts
  },
  props: {
    param: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
        vendorFlag: false,
        ramTechniqueCd: '',
        plantCd: '',
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      status: {
        riskChart: [],
        processImprChart: [],
        processRiskRegisterChart: [],
      },
      riskBeforeChart: {
        chartOptions: {
          chart: {
            id: 'before',
            // group: 'level',
            type: 'bar',
            height: 150
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '45%',
              endingShape: 'rounded'
            },
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val + "";
            },
            style: {
              fontSize: '14px',
              colors: ["#000000"]
            }
          },
          xaxis: {
            categories: [],
            labels: {
              style: {
                fontSize: '12px'
              },
              formatter: function (val) {
                return "[공정] " + val
              }
            }
          },
          yaxis: {
            title: {
              text: '개선 전 판단결과',
              style: {
                fontSize: '14px',
                fontWeight: 600,
              },
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " 건"
              }
            }
          },
          fill: {
            opacity: 1,
          },
        },
        series: [
          {
            name: '상',
            data: [],
          },
          {
            name: '중',
            data: [],
          },
          {
            name: '하',
            data: [],
          },
        ],
        chartWidth: '100%',
      },
      riskAfterChart: {
        chartOptions: {
          chart: {
            id: 'after',
            // group: 'level',
            type: 'bar',
            height: 150
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '45%',
              endingShape: 'rounded'
            },
          },
          // legend: {
          //   position: 'top',
          //   horizontalAlign: 'left',
          //   offsetX: 40
          // },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val + "";
            },
            style: {
              fontSize: '14px',
              colors: ["#000000"]
            }
          },
          xaxis: {
            categories: [],
            labels: {
              style: {
                fontSize: '12px'
              },
              formatter: function (val) {
                return "[공정] " + val
              }
            }
          },
          yaxis: {
            title: {
              text: '개선 후 판단결과',
              style: {
                fontSize: '14px',
                fontWeight: 600,
              },
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " 건"
              }
            }
          },
          fill: {
            opacity: 1,
          },
        },
        series: [
          {
            name: '상',
            data: [],
          },
          {
            name: '중',
            data: [],
          },
          {
            name: '하',
            data: [],
          },
        ],
        chartWidth: '100%',
      },
      hazardDisasterChart: {
        chartOptions: {
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '45%',
              endingShape: 'rounded'
            },
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val + "";
            },
            style: {
              fontSize: '14px',
              colors: ["#000000"]
            }
          },
          xaxis: {
            categories: [],
            labels: {
              style: {
                fontSize: '12px'
              },
              formatter: function (val) {
                return val
              }
            }
          },
          yaxis: {
            title: {
              text: '재해유형별 판단결과 건 수',
              style: {
                fontSize: '14px',
                fontWeight: 600,
              },
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " 건"
              }
            }
          },
          fill: {
            opacity: 1,
          },
        },
        series: [
          {
            name: '상',
            data: [],
          },
          {
            name: '중',
            data: [],
          },
          {
            name: '하',
            data: [],
          },
        ],
        chartWidth: '100%',
      },
      processImprChart: {
        chartOptions: {
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '45%',
              endingShape: 'rounded'
            },
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val + "";
            },
            style: {
              fontSize: '14px',
              colors: ["#000000"]
            }
          },
          xaxis: {
            categories: [],
            labels: {
              style: {
                fontSize: '12px'
              },
              formatter: function (val) {
                return "[공정] " + val
              }
            }
          },
          yaxis: {
            title: {
              text: '개선 건 수',
              style: {
                fontSize: '14px',
                fontWeight: 600,
              },
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " 건"
              }
            }
          },
          fill: {
            opacity: 1,
          },
        },
        series: [
          {
            name: '진행 건 수',
            data: [],
          },
          {
            name: '지연 건 수',
            data: [],
          },
          {
            name: '완료 건 수',
            data: [],
          },
        ],
        chartWidth: '80%',
      },
      processRiskRegisterChart: {
        chartOptions: {
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '45%',
              endingShape: 'rounded'
            },
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
          },
          stroke: {
            show: true,
            width: 2,
            // colors: ['#FFB200', '#EB5B00', '#2E236C']
            colors: ['transparent']
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val + "";
            },
            style: {
              fontSize: '14px',
              colors: ["#000000"]
            }
          },
          xaxis: {
            categories: [],
            labels: {
              style: {
                fontSize: '12px'
              },
              formatter: function (val) {
                return "[공정] " + val
              }
            }
          },
          yaxis: {
            title: {
              text: '위험등록부 건 수',
              style: {
                fontSize: '14px',
                fontWeight: 600,
              },
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " 건"
              }
            }
          },
          fill: {
            opacity: 1,
          },
          // colors: ['transparent']
        },
        series: [
        ],
        chartWidth: '80%',
      },
      riskStandardItems: [],
      editable: true,
      listRiskStandardUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    listUrl() {
      let url = null;
      if (this.param.ramTechniqueCd === 'RT00000001') {
        // HAZOP (o)
        url = selectConfig.ram.hazop.status.url;
      } else if (this.param.ramTechniqueCd === 'RT00000005') {
        // K-PSR (o)
        url = selectConfig.ram.kpsr.status.url;
      } else if (this.param.ramTechniqueCd === 'RT00000010') {
        // Check-list (o)
        url = selectConfig.ram.checklist.status.url;
      } else if (this.param.ramTechniqueCd === 'RT00000015') {
        // JRA (o)
        url = selectConfig.ram.jsa.status.url;
      } else if (this.param.ramTechniqueCd === 'RT00000020') {
        // KRAS (o)
        url = selectConfig.ram.kras.status.url;
      } else if (this.param.ramTechniqueCd === 'RT00000025') {
        // 4M (o)
        url = selectConfig.ram.fm.status.url;
      } else if (this.param.ramTechniqueCd === 'RT00000030') {
        // CHARM (o)
        url = selectConfig.ram.charm.status.url;
      } else if (this.param.ramTechniqueCd === 'RT00000035') {
        // 3단계 판단법 (o)
        url = selectConfig.ram.third.status.url;
      }
      return url;
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listRiskStandardUrl = selectConfig.ram.riskThirdStandard.list.url
      // list setting
      this.getRiskStandardItems();
    },
    getRiskStandardItems() {
      this.$http.url = this.listRiskStandardUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.param.ramRiskAssessmentPlanId,
      }
      this.$http.request((_result) => {
        this.riskStandardItems = _result.data;
        this.getList();
      },);
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.param.ramRiskAssessmentPlanId,
        plantCd: this.param.plantCd
        // vendorFlag: this.param.vendorFlag ? 1 : 0
      }
      this.$http.request((_result) => {
        this.$_.extend(this.status, _result.data);

        this.setRiskChart();
        this.setHazardDisasterChart();
        this.setProcessImprChart();
        this.setProcessRiskRegisterChart();
      },);
    },
    setRiskChart() {
      this.$set(this.riskBeforeChart.chartOptions, 'colors', this.$_.map(this.riskStandardItems, 'riskColor'))
      this.$set(this.riskBeforeChart.chartOptions.xaxis, 'categories', this.$_.map(this.status.riskChart, 'processName'))
      // this.$set(this.riskChart.chartOptions, 'labels', this.$_.map(this.status.riskChart, 'risk'))
      let seriesBefore = [
        {
          name: '상',
          data: this.$_.map(this.status.riskChart, 'topBeforeLevel'),
        },
        {
          name: '중',
          data: this.$_.map(this.status.riskChart, 'middleBeforeLevel'),
        },
        {
          name: '하',
          data: this.$_.map(this.status.riskChart, 'underBeforeLevel'),
        },
      ];
      this.$set(this.riskBeforeChart, 'series', seriesBefore)
      this.riskBeforeChart.chartWidth = '100%';

      this.$set(this.riskAfterChart.chartOptions, 'colors', this.$_.map(this.riskStandardItems, 'riskColor'))
      this.$set(this.riskAfterChart.chartOptions.xaxis, 'categories', this.$_.map(this.status.riskChart, 'processName'))
      // this.$set(this.riskChart.chartOptions, 'labels', this.$_.map(this.status.riskChart, 'risk'))
      let seriesAfter = [
        {
          name: '상',
          data: this.$_.map(this.status.riskChart, 'topAfterLevel'),
        },
        {
          name: '중',
          data: this.$_.map(this.status.riskChart, 'middleAfterLevel'),
        },
        {
          name: '하',
          data: this.$_.map(this.status.riskChart, 'underAfterLevel'),
        },
      ];
      this.$set(this.riskAfterChart, 'series', seriesAfter)
      this.riskAfterChart.chartWidth = '100%';

      this.$refs['riskBeforeChart'].refresh();
      this.$refs['riskAfterChart'].refresh();
    },
    setHazardDisasterChart() {
      this.$set(this.hazardDisasterChart.chartOptions, 'colors', this.$_.map(this.riskStandardItems, 'riskColor'))
      this.$set(this.hazardDisasterChart.chartOptions.xaxis, 'categories', this.$_.map(this.status.hazardDisasterChart, 'hazardDisasterTypeName'))
      // this.$set(this.riskChart.chartOptions, 'labels', this.$_.map(this.status.riskChart, 'risk'))
      let seriesBefore = [
        {
          name: '상',
          data: this.$_.map(this.status.hazardDisasterChart, 'topHazardCount'),
        },
        {
          name: '중',
          data: this.$_.map(this.status.hazardDisasterChart, 'middleHazardCount'),
        },
        {
          name: '하',
          data: this.$_.map(this.status.hazardDisasterChart, 'underHazardCount'),
        },
      ];
      this.$set(this.hazardDisasterChart, 'series', seriesBefore)
      this.hazardDisasterChart.chartWidth = '100%';

      this.$refs['hazardDisasterChart'].refresh();
    },
    setProcessImprChart() {
      // 공정별 개선대책 수립 및 실시
      // processName	cntIng	cntInaction	cntComplete
      // RM-T3 공정	  0	      0	          0
      // 건조-탈수	  0	      0	          0
      // this.$set(this.processImprChart.chartOptions, 'colors', this.$_.map(this.status.processImprChart, 'color'))
      this.$set(this.processImprChart.chartOptions.xaxis, 'categories', this.$_.map(this.status.processImprChart, 'processName'))
      let series = [
        {
          name: this.$comm.getLangLabel('LBL0001213'), // 진행 건 수
          data: this.$_.map(this.status.processImprChart, 'cntIng'),
        },
        {
          name: this.$comm.getLangLabel('LBL0001214'), // 완료 건 수
          data: this.$_.map(this.status.processImprChart, 'cntComplete'),
        }
      ];
      if (!this.param.vendorFlag) {
        series.splice(1, 0, {
          name: this.$comm.getLangLabel('LBL0001215'), // 지연 건 수
          data: this.$_.map(this.status.processImprChart, 'cntInaction'),
        });
      }
      this.$set(this.processImprChart, 'series', series)

      this.processImprChart.chartWidth = '100%';
      this.$refs['processImprChart'].refresh();
    },
    setProcessRiskRegisterChart() {
      // 3단계 판단법 (o)
      this.$set(this.processRiskRegisterChart.chartOptions.xaxis, 'categories', this.$_.map(this.status.processRiskRegisterChart, 'processName'))
      this.processRiskRegisterChart.series.push({
        name: 'process',
        data: this.$_.map(this.status.processRiskRegisterChart, 'cntProcess')
      })

      this.processRiskRegisterChart.chartWidth = '100%';
      this.$refs['processRiskRegisterChart'].refresh();
    },
  }
};
</script>